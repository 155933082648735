import React from "react";
import "./Step.scss";
import Bar from "../components/Bar";
import Layout from "../components/Layout";
import ImgStep0201 from "../images/step0201.png";
import ImgStep0202 from "../images/step0202.png";

// Path
const step01Path = "/Step01";
const step03Path = "/Step03";

const step02Page = () => {
  return (
    <Layout
      step="2"
      returnHref={step01Path}
      nextHref={step03Path}
      returnDataColor="secondary"
      nextDataColor="primary"
      dataCheck01="done"
      dataCheck02="wip"
      dataCheck03=""
      dataCheck04=""
    >
      <Bar number="2" text="デスクの天板の形を選択してください。" />

      <div className="section-lead">
        <p>
          作業スペースに応じて選択しましょう。
          <br />
          L字天板は作業スペースが広いですが、足を6本使うので注意が必要です。
        </p>
      </div>

      <div className="section-inner">
        <div className="card">
          <span className="card-title">I字天板</span>
          <p className="card-lead">
            通常の天板の形です。組み立てが簡単、いろいろなレイアウトにすることが可能といったメリットがあります。
          </p>
          <div className="card-img">
            <img src={ImgStep0201} alt="sample" />
          </div>
          <ul className="card-feature">
            <li>特徴</li>
            <li>I字天板の特徴やメリット・デメリット</li>
            <li>I字天板の特徴やメリット・デメリット</li>
            <li>I字天板の特徴やメリット・デメリット</li>
            <li>I字天板の特徴やメリット・デメリット</li>
            <li>I字天板の特徴やメリット・デメリット</li>
          </ul>
        </div>

        <div className="card">
          <span className="card-title">L字天板</span>
          <p className="card-lead">
            L字型の天板の形です。場所を広くとってしまいまいますが、その分作業スペースが広く様々な利用方法があります。
          </p>
          <div className="card-img">
            <img src={ImgStep0202} alt="sample" />
          </div>
          <ul className="card-feature">
            <li>特徴</li>
            <li>L字天板の特徴やメリット・デメリット</li>
            <li>L字天板の特徴やメリット・デメリット</li>
            <li>L字天板の特徴やメリット・デメリット</li>
            <li>L字天板の特徴やメリット・デメリット</li>
            <li>L字天板の特徴やメリット・デメリット</li>
          </ul>
        </div>
      </div>

      <div className="section-btn">
        <input type="radio" name="radio" id="radio1" />
        <label for="radio1">I字天板</label>
        <input type="radio" name="radio" id="radio2" />
        <label for="radio2">L字天板</label>
        <p>種類を選択し、「次へ」を押してください。</p>
      </div>
    </Layout>
  );
};

export default step02Page;
